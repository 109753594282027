import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import io from 'socket.io-client';

import { Gateway } from 'package-types';
import { FridgeDoorStatusChanged } from 'package-types/devices/events';

import { environment } from '../../../environments/environment';
import { ProductService } from '../product/product.service';
import { FridgeService } from '../fridge/fridge.service';
import { WeightingService } from 'src/app/services/weighting/weighting.service';
import { NotificationService } from '../notification/notification.service';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  public socket;
  socketChange: EventEmitter<boolean> = new EventEmitter();
  sectionTimeout;
  framesTimeout;

  constructor(
    public productService: ProductService,
    private fridgeService: FridgeService,
    public weightingService: WeightingService,
    public notificationService: NotificationService,
  ) {}

  public listen(eventName: string) {
    return new Observable((subscriber) => {
      this.socket.on(eventName, (data) => {
        subscriber.next(data);
      });
    });
  }

  public emit(eventName: string, data: any) {
    this.socket.emit(eventName, data);
  }

  public setupSocketConnection() {
    this.socket = io(environment.apiUrl);
    this.subscribeOnSocketEvents();
    this.listen('connect').subscribe(() => {
      this.emitSocketChangeEvent(true);
      setTimeout(
        () => this.emit('userAuthenticate', { token: localStorage.getItem('token') }),
        200,
      );
    });

    this.listen('disconnect').subscribe(() => {
      this.emitSocketChangeEvent(false);
    });
  }

  public joinRoom(fridgeId, room) {
    this.emit('backOfficeJoinRoom', {
      room,
      fridgeId,
      token: localStorage.getItem('token'),
    });
  }

  public leaveRoom(fridgeId, room) {
    this.emit('backOfficeLeaveRoom', {
      room,
      fridgeId,
    });
  }

  public subscribeOnSocketEvents() {
    this.socket.on('eventBus', async (event, payload) => {
      switch (event) {
        case 'DEVICES_RACK_INITIAL_INVENTORY':
          // @ts-ignore
          this.productService.productPlacement = payload.inventoryRacks;
          this.productService.sortPlacement();
          this.fridgeService.inventoryProcess = false;
          break;
        case 'DEVICES_SECTION_INVENTORY_UPDATED':
          this.productService.replaceSection(payload.section);
          this.fridgeService.inventoryProcess = true;
          break;
        case 'DEVICES_SECTIONS_INVENTORY_UPDATED':
          for (const section of payload.sections) {
            this.productService.replaceSection(section);
          }
          this.fridgeService.inventoryProcess = true;
          break;
        case 'DEVICES_FRIDGE_STATE_CHANGED':
          this.fridgeService.changeFridgeState(payload.fridgeId, payload.stateMachine);
          break;
        case 'DEVICES_FRIDGE_CONNECTED':
          this.fridgeService.changeFridgeConnection(payload.fridgeId, true);
          break;
        case 'DEVICES_FRIDGE_DISCONNECTED':
          this.fridgeService.changeFridgeConnection(payload.fridgeId, false);
          break;
        case 'DEVICES_FRIDGE_SUBSYSTEMS_STATE_CHANGED':
          this.fridgeService.changeSubSystemState(payload.fridgeId, payload.subSystemsState);
          break;
        case 'DEVICES_FRIDGE_DOOR_STATUS_CHANGED':
          const { fridgeId, doorStatus, rackNumber } = payload as FridgeDoorStatusChanged;
          this.fridgeService.changeDoorState(fridgeId, doorStatus, rackNumber);
          break;
        case 'DEVICES_WEIGHING':
          this.weightingService.setWeighingIntermediateResult(payload);
          break;
        case 'DEVICES_WEIGHING_RESULT':
          this.weightingService.setWeighingResult(payload);
          break;
        default:
          console.log(`unknown event -> ${event}`);
          break;
      }
    });
  }
  public emitSocketChangeEvent(status) {
    this.socketChange.emit(status);
  }
  public getSocketChangeEmitter() {
    return this.socketChange;
  }

  public clearSectionTimeout() {
    clearTimeout(this.sectionTimeout);
  }
  public getSensors(fridgeId) {
    this.socket.emit(
      Gateway.SocketEvents.FromUser.Names.GET_FRIDGE_CURRENT_WEIGHT_SENSOR_VALUES,
      { params: { fridgeId }, meta: { xid: 'service-man' } },
      async (error, response) => {
        if (error) {
          console.error(error);
          this.fridgeService.showWeightSensorsValues = false;
          this.clearSectionTimeout();
          await this.notificationService.notifyError(error.data.error.message || 'Error');
        } else {
          if (response.sections) {
            this.fridgeService.weightValues = {};
            for (const section of response.sections) {
              this.fridgeService.weightValues[section.sectionId] = section;
            }
            this.sectionTimeout = setTimeout(
              () => this.fridgeService.showWeightSensorsValues && this.getSensors(fridgeId),
              1000,
            );
          }
        }
      },
    );
  }
  public getStreamerFrame(fridgeId) {
    this.socket.emit(
      Gateway.SocketEvents.FromUser.Names.GET_STREAMER_FRAME,
      { params: { fridgeId }, meta: { xid: 'service-man' } },
      async (error, response: Gateway.SocketEvents.FromUser.GetStreamerFrameResponse) => {
        clearTimeout(this.framesTimeout);
        if (error) {
          console.error(error);
          this.fridgeService.streamer = {
            showFrames: false,
            frames: [],
            packetNumber: 0,
          };
          await this.notificationService.notifyError(error.data.error.message || 'Error');
        } else {
          if (response.cameras?.length) {
            this.fridgeService.streamer.frames = response.cameras.map((c) => {
              return {
                timestamp: new Date(c.frame.timestamp).toISOString(),
                src: this.getFrameSrc(c, 'buffer'),
              };
            });
            this.fridgeService.streamer.packetNumber += 1;
            if (this.fridgeService.streamer.packetNumber > 10) {
              console.log('Stop max frame number is 10');
              this.fridgeService.streamer = {
                showFrames: false,
                frames: [],
                packetNumber: 0,
              };
            } else {
              this.framesTimeout = setTimeout(
                () => this.fridgeService.streamer.showFrames && this.getStreamerFrame(fridgeId),
                5000,
              );
            }
          }
        }
      },
    );
  }
  private getFrameSrc(cameraPacket, format) {
    let src;

    if (cameraPacket.status !== 'NOTINITIALIZED') {
      let base64String;
      if (format === 'buffer') {
        base64String = this.encodeBuffer(new Uint8Array(cameraPacket.frame.buffer));
      } else {
        base64String = cameraPacket.frame.buffer;
      }
      src = `data:image/jpeg;base64,${base64String}`;
    }

    return src;
  }
  // public method for encoding an Uint8Array to base64
  private encodeBuffer(input) {
    const keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
    let output = '';
    // tslint:disable-next-line:one-variable-per-declaration
    let chr1, chr2, chr3, enc1, enc2, enc3, enc4;
    let i = 0;

    while (i < input.length) {
      chr1 = input[i++];
      chr2 = i < input.length ? input[i++] : Number.NaN; // Not sure if the index
      chr3 = i < input.length ? input[i++] : Number.NaN; // checks are needed here

      // tslint:disable-next-line:no-bitwise
      enc1 = chr1 >> 2;
      // tslint:disable-next-line:no-bitwise
      enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
      // tslint:disable-next-line:no-bitwise
      enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
      // tslint:disable-next-line:no-bitwise
      enc4 = chr3 & 63;

      if (isNaN(chr2)) {
        enc3 = enc4 = 64;
      } else if (isNaN(chr3)) {
        enc4 = 64;
      }
      output +=
        keyStr.charAt(enc1) + keyStr.charAt(enc2) + keyStr.charAt(enc3) + keyStr.charAt(enc4);
    }
    return output;
  }
}
