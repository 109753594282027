<div class="combo-products" *ngIf="activeProducts.length > 0">
  <div class="combo-products__total">
    <span class="old-total">
      {{ utils.formatMoneyByMerchant(totals.originalTotal, merchant) }}
    </span>
    <span class="new-total">
      {{ utils.formatMoneyByMerchant(totals.comboTotal, merchant) }}
      <span class="factor">({{ totals.factor }})</span>
    </span>
  </div>
  <div class="row">
    <div class="combo-products__item" *ngFor="let product of activeProducts; trackBy: track">
      <div class="combo-products__wrapper">
        <ng-template
          *ngIf="
            product.images && imagesLinks[product.images[0]]?.thumbnail;
            then image;
            else noImage
          "
        >
        </ng-template>
        <ng-template #image>
          <img [src]="imagesLinks[product.images[0]]?.thumbnail" />
          <div class="product-name-sub-title">{{ product.name }}</div>
          <div class="combo-products__qty">
            x{{ product.comboProduct.quantity }} (
            {{ utils.formatMoneyByMerchant(product.comboProduct.price, merchant) }})
          </div>
        </ng-template>
        <ng-template #noImage>
          <span class="product-name">{{ product.name }}</span>
          <div class="combo-products__qty">
            x{{ product.comboProduct.quantity }}
            {{ utils.formatMoneyByMerchant(product.comboProduct.price, merchant) }})
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="row" style="gap: 20px; margin-top: 20px">
    <div class="combo-products__button" (click)="edit.emit(combo)">edit</div>
    <div class="combo-products__button red" (click)="remove.emit(combo._id)">remove</div>
  </div>
</div>
