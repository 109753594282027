import { Component, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import axios from 'axios';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user/user.service';
import { Api } from './services/api/api.service';
import { environment } from '@environments/environment';
import { SocketService } from './services/socket/socket.service';
import { NotificationService } from './services/notification/notification.service';
import * as _ from 'lodash';
import { FridgeService } from 'src/app/services/fridge/fridge.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    public userService: UserService,
    private modalController: ModalController,
    public notificationService: NotificationService,
    private socketService: SocketService,
    private fridgeService: FridgeService,
    public translate: TranslateService,
  ) {
    this.initializeApp();

    translate.addLangs(['en', 'de']);
    translate.setDefaultLang('en');

    const browserLang = translate.getBrowserLang();
    const finalLanguage = browserLang.match(/en|de/) ? browserLang : 'de';
    if (localStorage.getItem('serviceman_lang')) {
      translate.use(localStorage.getItem('serviceman_lang'));
    } else {
      translate.use(finalLanguage);
      localStorage.setItem('serviceman_lang', finalLanguage);
    }
  }

  loading = true;
  socketSubscription: any;

  initializeApp() {
    axios.defaults.baseURL = environment.apiUrl;
    axios.interceptors.request.use((config) => {
      if (localStorage.getItem('token')) {
        config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
      }
      return config;
    });

    axios.interceptors.response.use(
      (response) => response.data,
      async (error) => {
        if (error.response?.data?.data?.length > 0) {
          await this.notificationService.notifyError(error.response.data.data[0].message);
        } else if (error.response?.data?.data?.error?.message) {
          await this.notificationService.notifyError(error.response.data.data.error.message);
        } else if (error.response?.data?.message) {
          await this.notificationService.notifyError(error.response?.data?.message);
        } else {
          await this.notificationService.notifyError(error.message || error);
        }

        if (error.response?.status === 401) {
          this.userService.logoutUser();
          const isModalOpened = await this.modalController.getTop();
          if (isModalOpened) {
            await this.modalController.dismiss();
          }
          await this.router.navigate(['/login']);
          this.loading = false;
        }
        return Promise.reject(error);
      },
    );
  }

  async ngOnInit() {
    this.userService.loginEvent.subscribe(async () => {
      this.loading = true;
      this.socketService.setupSocketConnection();
      await this.getGlobalData();
      this.loading = false;
    });
    if (localStorage.getItem('token')) {
      try {
        const { user } = await Api.getUserByToken();
        this.userService.setUserData(user);
        this.socketService.setupSocketConnection();
        await this.getGlobalData();
      } catch (e) {
        this.userService.logoutUser();
        this.loading = false;
        await this.router.navigate(['/']);
      }
    }
    this.loading = false;
  }

  public async getGlobalData() {
    const { merchants } = await Api.getMerchants();
    this.userService.setMerchants(merchants);
    await this.getFridges();

    if (this.socketService.socket?.connected) {
      this.joinStatusRoom(true);
    }
    this.socketSubscription = this.socketService.getSocketChangeEmitter().subscribe((status) => {
      this.joinStatusRoom(status);
      if (status) {
        this.getFridges();
      }
    });
  }

  async getFridges() {
    const { fridges } = await Api.getAllFridges();
    this.fridgeService.setFridges(fridges);
  }

  joinStatusRoom(status) {
    if (!status) return;
    _.map(this.fridgeService.fridges, (fridge) => {
      this.socketService.joinRoom(fridge._id, 'devicesFridgeStatus');
    });
  }
}
