<app-header [title]="'Create/Edit Combos'" (back)="router.navigate(['/home'])"></app-header>

<ion-content>
  <ion-item class="default-input merchant" *ngIf="!userService.haveMerchant">
    <ion-select
      (ionChange)="onMerchantChange($event)"
      [label]="i18n.instant('products.merchant')"
      labelPlacement="stacked"
      [value]="activeMerchant"
      [placeholder]="i18n.instant('products.select_one')"
    >
      <ion-select-option *ngFor="let merchant of userService.merchants" [value]="merchant._id">{{
        merchant.name
      }}</ion-select-option>
    </ion-select>
  </ion-item>

  <button
    class="action-btn"
    style="margin-bottom: 20px"
    (click)="openComboModal()"
    *ngIf="activeMerchant || userService.haveMerchant"
  >
    Create Combo
  </button>

  <app-combo-preview
    *ngFor="let combo of combos"
    [combo]="combo"
    [products]="products"
    [merchant]="
      userService.getMerchantById(
        userService.haveMerchant ? userService.userData.merchantId : activeMerchant
      )
    "
    [imagesLinks]="imagesLinks"
    (edit)="openComboModal($event)"
    (remove)="removeCombo($event)"
  ></app-combo-preview>
</ion-content>
